import DebugConsole from '@helper/functions/console';

import { invoiceAdminAPI } from './api';
import { handleErrorMessage } from './shared';

export async function getTerminalConfig() {
  try {
    const { data } = await invoiceAdminAPI().get(`/invapi/terminal/config`, {});
    return data as TerminalProperties[];
  } catch (error) {
    handleErrorMessage(error);
    return undefined;
  }
}

export async function setUserDefaultTerminal({ username, terminalID }) {
  try {
    const res = await invoiceAdminAPI().post(`/invapi/terminal/cashierterminal`, {
      cashierName: username,
      terminalID,
    });

    DebugConsole.debug(res);

    return true;
  } catch (error) {
    handleErrorMessage(error);
    return undefined;
  }
}
