import AES from 'crypto-js/aes';

import useAuthStore from '@src/store/auth';

import useAuth from '@helper/hooks/useAuth';

import { invoiceAPI, invoiceAdminAPI, publicAPI } from './api';
import { handleErrMessage, handleErrorMessage, handleSuccess } from './shared';

export const getInvoice = async ({ customerAccount, invoiceNumber }) => {
  try {
    //const { data } = await invoiceAPI().get(`/invoices?customer=${customerAccount}&invoice=${invoiceNumber}`);
    const user = useAuthStore.getState().userAuthState;
    let encryptedQueries = AES.encrypt(
      JSON.stringify({ customerAccount: customerAccount, invoiceNumber: invoiceNumber }),
      'pBzpnEu985ae',
    ).toString();
    if (user?.isLoggedIn) {
      const { data } = await invoiceAdminAPI().post(`/invapi/cashier/invoices`, {
        encryptedQueries: encryptedQueries,
      });

      return data;
    } else {
      const { data } = await invoiceAPI().post(`/invapi/invoices`, {
        encryptedQueries: encryptedQueries,
      });

      return data;
    }
  } catch (error) {
    handleErrorMessage(error);
    return null;
  }
};

export const postPaymentDetails = async (json) => {
  try {
    const { data } = await invoiceAPI().post('/invapi/payment', json);
    return data;
  } catch (error) {
    handleErrorMessage(error);
    return null;
  }
};

export const sendReceiptEmail = async (json) => {
  try {
    const { data } = await invoiceAPI().post('/invapi/sendmail-new', json);
    return data;
  } catch (error) {
    handleErrMessage('Unable to send mail');
  }
};

export const postGenerateReceipt = async (body) => {
  try {
    const { data } = await invoiceAPI().post(`/invapi/receipt/generate-pdf`, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await invoiceAPI().post(`/invapi/receipt/generate-pdf`, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};

export const medicalRecordSummaries = async (body) => {
  try {
    const { data } = await invoiceAPI().post(`/invapi/medicalRecord/generate-pdf`, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await invoiceAPI().post(`/invapi/medicalRecord/generate-pdf`, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};

export const sendRating = async ({ paymentType, rating, comment, retrievalReferenceNumber }) => {
  try {
    let encryptedQueries = AES.encrypt(
      JSON.stringify({
        paymentType: paymentType,
        rating: rating,
        comment: comment,
        retrievalReferenceNumber: retrievalReferenceNumber,
      }),
      'pBzpnEu985ae',
    ).toString();

    const { data } = await invoiceAPI().post(`/invapi/rating`, {
      encryptedQueries: encryptedQueries,
    });

    return data;
  } catch (error) {
    handleErrMessage('Unable to submit feedback. Please try again later.');
  }
};

export const getRatingReport = async (dateFrom, dateTo) => {
  try {
    const { data } = await invoiceAdminAPI().post(`/invapi/admin/rating`, {
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
    return data;
  } catch (error) {
    handleErrMessage('Unable to request report. Please try again later.');
  }
};

export async function getReceivableActivities(page) {
  const user = useAuthStore.getState().userAuthState;

  try {
    if (user?.isLoggedIn) {
      const { data } = await invoiceAdminAPI().post(`/invapi/cashier/recactivities`, null, {
        params: {
          page,
        },
      });

      return data;
    } else {
      const { data } = await invoiceAPI().post(`/invapi/recactivities`, null, {
        params: {
          page,
        },
      });
      return data;
    }
  } catch (err) {
    handleErrMessage('Unable to fetch merchant reference data. Please try again later.');

    return err;
  }
}

export async function getCashierReport(params){
  const header = {
    ["health-entity-code"]: params.healthEntityCode,
    ["creation-date-from"]: params.dateFrom,
    ["creation-date-to"]: params.dateTo
  }

  if(params.username){
    header['stafflink-user'] = params.username
  }

  if(params.receiptNo){
    header['receipt-number'] = params.receiptNo
  }

  if(params.paymentMethod){
    header['payment-method'] = params.paymentMethod
  }
  console.log(header);
  try{
    const { data } = await invoiceAdminAPI('',header).get(`/invapi/cashier/dailyreport`);
    return data;
  }catch(err){
    handleErrMessage('Unable to fetch cashier report. Please try again later.');

    return err;
  }
  
}

export async function getCashierTerminalExceptions(healthEntity = "", cashierName = "") {
  try {
    const header = {}

    let url = "/invapi/cashier/terminal-exceptions"

    if(healthEntity !== ""){
      url += `?healthEntity=${healthEntity}`;
      if(cashierName !== ""){
        url += `&cashierFullName=${cashierName}`
      }
    }

    const { data } = await invoiceAdminAPI('',header).get(url);
    return data;
  } catch (err) {
    handleErrMessage('Unable to fetch exceptions data. Please try again later.');
    return null;
  }
}

export async function putCashierTerminalExceptions(body) {
  try {
    const header = {}

    const { data } = await invoiceAdminAPI('',header).put(`/invapi/cashier/terminal-exceptions`, body);
    return data;
  } catch (err) {
    handleErrMessage('Unable to fetch exceptions data. Please try again later.');
    return err;
  }
}

export const postGenerateCashierPdfReceipt = async (body) => {
  try {
    const { data } = await publicAPI().post(`/invapi/report/generate-cashier-pdf`, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await publicAPI().post(`/invapi/report/generate-cashier-pdf`, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};

export const postGenerateCashierCsvReceipt = async (body) => {
  try {
    const { data } = await publicAPI().post(`/invapi/report/generate-cashier-csv`, body);
    return data.receiptBuffer;
  } catch (error) {
    try {
      const { data } = await publicAPI().post(`/invapi/report/generate-cashier-csv`, body);
      return data.receiptBuffer;
    } catch (e) {
      handleErrorMessage(e);
    }
  }
};
