import DebugConsole from '@helper/functions/console';
import { invoiceAPI } from '@helper/service/api';
import { handleErrMessage } from '@helper/service/shared';

export async function getCurrentOutageData(): Promise<OutageRecord> {
  try {
    const res = await invoiceAPI().post('/invapi/outage');
    if (!res || res.status >= 400) return undefined;

    return res.data;

    // return {
    //   SK: 'demo_db_sk_' + crypto.randomUUID(),
    //   expectedStartDateTime: DateTime.now().plus({ days: 1 }).toISO(),
    //   expectedEndDateTime: DateTime.now().plus({ days: 2 }).toISO(),
    //   outageType: 'planned',
    //   outageTitle: 'Demo Outage Title',
    //   outageDetails: 'Demo outage. Please ignore.',
    //   isDowntimeRequired: false,
    // };
  } catch (err) {
    DebugConsole.error(err);
    return undefined;
  }
}
