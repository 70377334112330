import { Suspense, lazy } from 'react';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Features from '@src/features.config';

import { ComponentLoading } from '@components/Loading';

import Layout from '@helper/layout';
import RouterDefaultLayout from '@helper/layout/RouterDefaultLayout';
import MinimalLayout from '@helper/layout/minimal';

import RouteRequireOu from './PageRequireOu';
import RouteRequireAuth from './RouteRequireAuth';

const Home = lazy(() => import('@screens/home/index'));
const InvoicePayment = lazy(() => import('@screens/invoice-payment'));
const Donations = lazy(() => import('@screens/donations'));
const DonationsContactUs = lazy(() => import('@screens/donations-contact-us'));
const DonationsFaqs = lazy(() => import('@screens/donations-faqs'));
const InvoiceContactUs = lazy(() => import('@screens/invoice-payment-contact-us'));
const Faqs = lazy(() => import('@screens/faqs'));
const InvoiceFaqs = lazy(() => import('@screens/invoice-faqs'));
const PageNotFound = lazy(() => import('@screens/page-not-found'));
const PageNotAuthorized = lazy(() => import('@screens/page-not-authorized'));
const Enable = lazy(() => import('@screens/enable'));
const EnableFaqs = lazy(() => import('@screens/enable-faqs'));
const MiscPayment = lazy(() => import('@screens/misc-payment'));
const MiscPaymentFaqs = lazy(() => import('@screens/misc-payment-faqs'));
const MiscPaymentContactForm = lazy(() => import('@screens/misc-payment-contact-form'));
const StaffPayment = lazy(() => import('@screens/staff-payment'));
const NSWMap = lazy(() => import('@screens/map'));
const InvoiceExceptionHandling = lazy(() => import('@screens/invoice-exception-handling'));

const MockCheckoutScript = lazy(() => import('@screens/mock-checkout-script'));
const CheckoutGateway = lazy(() => import('@screens/checkout-gateway'));
const CheckoutStepUpResponse = lazy(() => import('@screens/checkout-step-up-response'));
const CheckoutHostedTxResponse = lazy(
  () => import('@screens/checkout-hosted-response/transaction'),
);
const CheckoutHostedCancelResponse = lazy(() => import('@screens/checkout-hosted-response/cancel'));

const Admin = lazy(() => import('@screens/admin'));
const AdminDynamicForms = lazy(() => import('@screens/admin/dynamic-forms'));
const AdminDynamicFormsEdit = lazy(() => import('@screens/admin/dynamic-forms/edit-form'));
const AdminDynamicFormsAdd = lazy(() => import('@screens/admin/dynamic-forms/add-form'));

const AdminOutage = lazy(() => import('@screens/admin/outage'));
const AdminOutageEditor = lazy(() => import('@screens/admin/outage/edit'));

const AdminRating = lazy(() => import('@screens/admin/rating-report'));

const CashierAccess = lazy(() => import('@screens/admin/cashier-access/index'));
const QRCodeGeneration = lazy(() => import('@screens/admin/qr-code-generation/index'));
const CashierReport = lazy(() => import('@screens/cashier-report'));

const UserSettings = lazy(() => import('@screens/user-settings'));
const UserTerminalSelection = lazy(() => import('@screens/user-settings/terminal'));


// const Welcome = lazy(() => import('@screens/welcome'));
// const Accommodation = lazy(() => import('@screens/accommodation'));
// const Education = lazy(() => import('@screens/education'));
// const Cremation = lazy(() => import('@screens/cremation'));
// const MedicalRecords = lazy(() => import('@screens/medical-records'));
// const MedicalRecordsRequestViewer = lazy(() => import('@screens/medical-records/request-viewer'));

// const AdminMedicalRecordFees = lazy(() => import('@screens/admin/medical-records/fees'));
// const AdminMedicalRecordsFulfiller = lazy(() => import('@screens/admin/medical-records/fulfiller'));
// const AdminMedicalRecords = lazy(() => import('@screens/admin/medical-records'));
// const AdminMedicalRecordViewRequest = lazy(
//   () => import('@screens/admin/medical-records/fulfiller/pages/viewRequest'),
// );
// const AdminOrganisations = lazy(() => import('@screens/admin/organisations'));
// const AdminAddHealthEntity = lazy(() => import('@screens/admin/organisations/health-entities/add'));
// const AdminEditHealthEntity = lazy(
//   () => import('@screens/admin/organisations/health-entities/edit'),
// );
// const AdminHealthEntities = lazy(() => import('@screens/admin/organisations/health-entities'));
// const AdminAddSite = lazy(() => import('@screens/admin/organisations/sites/addSite'));
// const AdminEditSite = lazy(() => import('@screens/admin/organisations/sites/editSite'));
// const AdminUtilities = lazy(() => import('@screens/admin/utilities'));

export default function AppRouter(props) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {/* A catch-all route to display the not-found page */}
        <Route
          path="*"
          element={
            <Layout>
              <Navigate to="/not-found" replace={true} />
            </Layout>
          }
        />

        <Route
          path="/not-authorised"
          element={
            <Layout>
              <PageNotAuthorized />
            </Layout>
          }
        />
        <Route
          path="/not-found"
          element={
            <Layout>
              <PageNotFound />
            </Layout>
          }
        />

        {/* All normal pages should be put within this Route so that the default layout is applied */}
        <Route
          path="/"
          element={<RouterDefaultLayout />}
          handle={{
            crumb: () => ({ to: '/', name: 'Home' }),
          }}
        >
          <Route index element={<Home />} />

          <Route
            path="/faqs"
            element={<Faqs />}
            handle={{
              crumb: () => ({ to: 'faqs', name: 'Frequently Asked Questions' }),
            }}
          />

          <Route
            path="/invoice"
            handle={{
              crumb: () => ({ to: 'invoice', name: 'Invoice Payment' }),
            }}
          >
            <Route index element={<InvoicePayment />} />

            <Route
              path="contact-us"
              element={<InvoiceContactUs />}
              handle={{
                crumb: () => ({ to: 'contact-us', name: 'Contact Us' }),
              }}
            />
            <Route
              path="faqs"
              element={<InvoiceFaqs />}
              handle={{
                crumb: () => ({ to: 'faqs', name: 'Frequently Asked Questions' }),
              }}
            />
            {/* <Route
              path="exception-handling"
              element={<InvoiceExceptionHandling />}
              handle={{
                crumb: () => ({ to: 'exception-handling', name: 'Correct Error' }),
              }}
            /> */}
          </Route>

          <Route
            path="error-correction"
            element={<InvoiceExceptionHandling />}
            handle={{
              crumb: () => ({ to: 'error-correction', name: 'Error Correction' }),
            }}
          />

          <Route
            path="/donations"
            handle={{
              crumb: () => ({ to: 'donations', name: 'Donations' }),
            }}
          >
            <Route index element={<Donations />} />

            <Route
              path="contact-us"
              element={<DonationsContactUs />}
              handle={{
                crumb: () => ({ to: 'contact-us', name: 'Contact Us' }),
              }}
            />
            <Route
              path="faqs"
              element={<DonationsFaqs />}
              handle={{
                crumb: () => ({ to: 'faqs', name: 'Frequently Asked Questions' }),
              }}
            />
          </Route>

          <Route
            path="/other-payments"
            handle={{
              crumb: () => ({ to: 'other-payments', name: 'Non-Invoice Payment' }),
            }}
          >
            <Route index element={<MiscPayment />} />

            <Route
              path="contact-us"
              element={<MiscPaymentContactForm />}
              handle={{
                crumb: () => ({ to: 'contact-us', name: 'Contact Us' }),
              }}
            />
            <Route
              path="faqs"
              element={<MiscPaymentFaqs />}
              handle={{
                crumb: () => ({ to: 'faqs', name: 'Frequently Asked Questions' }),
              }}
            />
          </Route>

          <Route
            path="/enablensw"
            element={<RouteRequireOu roles={['cashier']} healthEntity={'HS'} />}
            handle={{
              crumb: () => ({ to: 'enablensw', name: 'EnableNSW' }),
            }}
          >
            <Route index element={<Enable />} />

            <Route
              path="faqs"
              element={<EnableFaqs />}
              handle={{
                crumb: () => ({ to: 'faqs', name: 'Frequently Asked Questions' }),
              }}
            />
          </Route>

          <Route
            path="/enablensw"
            handle={{
              crumb: () => ({ to: 'enablensw', name: 'EnableNSW' }),
            }}
          ></Route>

          {Features.services.staffPayments && (
            <Route
              path="/staff-payment"
              handle={{
                crumb: () => ({ to: 'staff-payment', name: 'Staff Payment' }),
              }}
            >
              <Route index element={<StaffPayment />} />
            </Route>
          )}

          {Features.admin.cashierReport && (
            <Route
              path="/cashier/cashier-report"
              element={<RouteRequireAuth roles={['cashier']} />}
              handle={{
                crumb: () => ({ to: 'cashier-report', name: 'Cashier' }),
              }}
            >
              <Route index element={<CashierReport />} />)
            </Route>
          )}

          {Features.services.nswHealthMap && <Route path="/map" element={<NSWMap />} />}

          {/* {Features.services.medicalRecords && (
          <>
            <Route path={'/medical-records'}>
              <Route index element={<MedicalRecords />} />

              <Route path={'apply/:formStep?'} element={<MedicalRecords />} />
              <Route path={'view-request/:id'} element={<MedicalRecordsRequestViewer />} />
            </Route>
          </>
        )} */}

          {/* <Route path="/rating-report" element={<RatingReport />} /> */}
          {/* <Route path="/welcome" element={<Welcome />} /> */}

          {/* {Features.services.accommodation && (
          <Route path="/accommodation" element={<Accommodation />} />
        )}
        {Features.services.cremation && <Route path="/cremation" element={<Cremation />} />}
        {Features.services.education && <Route path="/education" element={<Education />} />} */}

          {Features.userSettings && (
            <Route
              path="/user-settings"
              element={<RouteRequireAuth />}
              handle={{
                crumb: () => ({ to: 'user-settings', name: 'User Settings' }),
              }}
            >
              <Route index element={<UserSettings />} />

              <Route
                path="default-terminal"
                element={<RouteRequireAuth roles={['cashier']} />}
                handle={{ crumb: () => ({ to: 'default-terminal', name: 'Default Terminal' }) }}
              >
                <Route index element={<UserTerminalSelection />} />
              </Route>
            </Route>
          )}

          {/* Start of admin routes */}
          {/* Note: All roles defined at this route will also be applied to sub-routes. To allow individual routes to have different roles requirement, remove/reduce the `roles` prop at this route. Then, wrap the sub-routes with their own `<RouteRequireAuth />` and roles prop. */}
          <Route
            path="/admin"
            element={<RouteRequireAuth roles={['admin']} />}
            handle={{
              crumb: () => ({ to: 'admin', name: 'Admin' }),
            }}
          >
            <Route index element={<Admin />} />

            <Route
              path="dynamic-forms"
              handle={{
                crumb: () => ({ to: 'dynamic-forms', name: 'Dynamic Forms' }),
              }}
            >
              <Route index element={<AdminDynamicForms />} />

              <Route
                path="add/:id?"
                element={<AdminDynamicFormsAdd />}
                handle={{
                  crumb: (data) => ({
                    name: `Create Dynamic Form${data.pathname.endsWith('/add') ? '' : ' (Copy)'}`,
                  }),
                }}
              />
              <Route
                path="edit/:id"
                element={<AdminDynamicFormsEdit />}
                handle={{
                  crumb: () => ({ name: 'Edit Dynamic Form' }),
                }}
              />
            </Route>

            {Features.admin.outageManager && (
              <Route
                path="outage"
                handle={{
                  crumb: () => ({ to: 'outage', name: 'Outage' }),
                }}
              >
                <Route index element={<AdminOutage />} />

                <Route
                  path="add"
                  element={<AdminOutageEditor />}
                  handle={{
                    crumb: () => ({ name: 'Create Outage' }),
                  }}
                />
                <Route
                  path="edit/:id"
                  element={<AdminOutageEditor />}
                  handle={{
                    crumb: () => ({ name: 'Edit Outage' }),
                  }}
                />
              </Route>
            )}

            {Features.admin.ratingReport && (
              <Route
                path="rating-report"
                element={<AdminRating />}
                handle={{
                  crumb: () => ({ to: 'rating-report', name: 'Rating Report' }),
                }}
              />
            )}

            {Features.admin.cashierAccess && (
              <Route
                path="/admin/non-cashier-access"
                element={<CashierAccess />}
                handle={{
                  crumb: () => ({ to: 'non-cashier-access', name: 'Non Cashier Access' }),
                }}
              />
            )}

            {Features.admin.QRCodeGeneration && (
              <Route
                path="/admin/qr-code-generation"
                element={<QRCodeGeneration />}
                handle={{
                  crumb: () => ({ to: 'qr-code-generation', name: 'QR Code Generation' }),
                }}
              />
            )}

            {/* {Features.admin.medicalRecords && (
            <>
              <Route path="/admin/medical-records">
                <Route index element={<AdminMedicalRecords />} />

                <Route path={'fees/:action?'} element={<AdminMedicalRecordFees />} />
                <Route path={'fees/edit/:id?'} element={<AdminMedicalRecordFees />} />

                <Route path={'fulfiller'} element={<AdminMedicalRecordsFulfiller />} />
                <Route path={'fulfiller/view/:id'} element={<AdminMedicalRecordViewRequest />} />
              </Route>
            </>
          )} */}

            {/* {Features.admin.organisations && (
            <>
              <Route path="/admin/organisations">
                <Route index element={<AdminOrganisations />} />

                <Route path={'add-site/:id?'} element={<AdminAddSite />} />
                <Route path={'edit-site/:id'} element={<AdminEditSite />} />

                <Route path="health-entities">
                  <Route index element={<AdminHealthEntities />} />

                  <Route path={'add/:id?'} element={<AdminAddHealthEntity />} />
                  <Route path={'edit/:id'} element={<AdminEditHealthEntity />} />
                </Route>
              </Route>
            </>
          )} */}

            {/* {Features.admin.devTools && (
            <>
              <Route path={'/admin/utilities'} element={<AdminUtilities />} />
            </>
          )} */}
          
          </Route>
          {/* End of admin routes */}


          <Route
            path="/cashier/error-correction"
            element={<RouteRequireAuth roles={['cashier']} />}
            handle={{
              crumb: () => ({ to: 'error-correction', name: 'Error Correction' }),
            }}
          >
            <Route index element={<InvoiceExceptionHandling />} />)
          </Route>
        </Route>
        {/* End of all normal routes.*/}

        

        {/* Routes below should have their own layout */}

        {Features.services.mockCheckout && (
          <>
            <Route
              path="/checkout/:checkoutInitToken"
              element={
                <MinimalLayout>
                  <CheckoutGateway />
                </MinimalLayout>
              }
            />

            <Route path="/checkout/step-up-response" element={<CheckoutStepUpResponse />} />
            <Route
              path="/checkout/hosted-transaction-response"
              element={<CheckoutHostedTxResponse />}
            />
            <Route
              path="/checkout/hosted-cancel-response"
              element={<CheckoutHostedCancelResponse />}
            />
            <Route
              path="/mock-checkout-script"
              element={
                <MinimalLayout>
                  <MockCheckoutScript />
                </MinimalLayout>
              }
            />
          </>
        )}
      </>,
    ),
  );

  return (
    <Suspense fallback={<ComponentLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
