import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import useAuthStore from '@src/store/auth';

import { LOGOUT_RELOGIN_URL, LOGOUT_URL } from '@helper/constants';
import DebugConsole from '@helper/functions/console';

const TIME_BEFORE_LOGOUT = 300000;

const FINAL_WARNING_BEFORE_LOGOUT = 60000;

const redirectTo = (url) => {
  return new Promise<void>((resolve, reject) => {
    window.location.href = url;
    resolve();
  });
};

export type AuthState = {
  UserName: string;
  UserFullName: string;
  UserEmail: string;
  Roles: Array<string>;
  PaymentMethod: Array<string>;
  isLoggedIn: boolean | undefined;
  isLoading: boolean;
  logout: () => void;
  openLogoutDialog: boolean;
  updateOpenLogoutDialog: (newOpenLogoutDialog: boolean) => void;
  textDialog: string;
  HealthEntity: Array<string>;
  openNoRoleDialog: boolean;
  updateOpenNoRoleDialog: (bool: boolean) => void;
  defaultTerminalID: string;
};

export default function useAuth(): AuthState {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([
    'AccessToken',
    'Role',
    'UserFullName',
    'UserName',
    'UserEmail',
    'HealthEntity',
    'NoRoleLoggedIn',
    'PaymentMethod',
    'DefaultTerminalID',
  ]);

  const handleLogout = useCallback(() => {
    console.log('Log out');
  }, []);

  const updateOpenLogoutDialog = (newOpenLogoutDialog) => {
    setUser((prevUser) => ({
      ...prevUser,
      openLogoutDialog: newOpenLogoutDialog,
    }));
  };

  const updateOpenNoRoleDialog = (bool) => {
    setUser((prevUser) => ({
      ...prevUser,
      openNoRoleDialog: bool,
    }));
  };

  const [user, setUser] = useState<AuthState>({
    UserName: '',
    UserFullName: '',
    UserEmail: '',
    Roles: [],
    PaymentMethod: [],
    isLoggedIn: undefined,
    isLoading: true,
    logout: handleLogout,
    openLogoutDialog: false,
    updateOpenLogoutDialog: updateOpenLogoutDialog,
    HealthEntity: [],
    textDialog: '',
    openNoRoleDialog: false,
    updateOpenNoRoleDialog: updateOpenNoRoleDialog,
    defaultTerminalID: '',
  });

  useEffect(() => {
    DebugConsole.debug(`Login Changed: ${user.UserName}, ${user.UserFullName}, ${user.Roles}`);
  }, [user.Roles, user.UserFullName, user.UserName]);

  const setUserAuthState = useAuthStore((s) => s.setUserAuthState);

  useEffect(() => {
    setUserAuthState(user);
  }, [setUserAuthState, user]);

  useEffect(() => {
    if (cookies.UserName || cookies.UserFullName) {
      const currentTime = new Date().getTime();

      const jwtDecoded = jwtDecode(cookies.AccessToken);
      const expiryTime = jwtDecoded.exp * 1000;

      const userDetails = {
        UserName: cookies.UserName,
        UserFullName: cookies.UserFullName,
        UserEmail: cookies.UserEmail,
        Roles: cookies.Role ? cookies.Role : [],
        PaymentMethod: cookies.PaymentMethod
          ? cookies.PaymentMethod
          : ['cash', 'cheque', 'terminal', 'creditCard'],
        HealthEntity: cookies.HealthEntity ? cookies.HealthEntity : [],
        defaultTerminalID: cookies.DefaultTerminalID || '',
      };

      if (expiryTime && currentTime >= Number(expiryTime)) {
        setUser({
          UserName: '',
          UserFullName: '',
          UserEmail: '',
          Roles: cookies.Role ? cookies.Role : [],
          PaymentMethod: cookies.PaymentMethod ? cookies.PaymentMethod : [],
          isLoggedIn: false,
          isLoading: false,
          logout: handleLogout,
          openLogoutDialog: false,
          updateOpenLogoutDialog: updateOpenLogoutDialog,
          HealthEntity: [],
          textDialog: '',
          openNoRoleDialog: false,
          updateOpenNoRoleDialog: updateOpenNoRoleDialog,
          defaultTerminalID: '',
        });
        window.location.href = LOGOUT_URL;
      } else if (expiryTime && currentTime < Number(expiryTime)) {
        setTimeout(
          () => {
            setUser({
              ...userDetails,
              isLoggedIn: true,
              isLoading: false,
              logout: handleLogout,
              openLogoutDialog: true,
              updateOpenLogoutDialog: updateOpenLogoutDialog,
              textDialog:
                'WARNING: Your login session will expire in 5 minutes. Please complete your transaction',
              openNoRoleDialog: false,
              updateOpenNoRoleDialog: updateOpenNoRoleDialog,
            });
          },
          Math.max(10, Number(expiryTime - TIME_BEFORE_LOGOUT) - currentTime),
        );

        setTimeout(
          () => {
            setUser({
              ...userDetails,
              isLoggedIn: true,
              isLoading: false,
              logout: handleLogout,
              openLogoutDialog: true,
              updateOpenLogoutDialog: updateOpenLogoutDialog,
              textDialog:
                'WARNING: Your session will timeout in one minute. Please complete your current transaction and logout and re-login.',
              openNoRoleDialog: false,
              updateOpenNoRoleDialog: updateOpenNoRoleDialog,
            });
          },
          Math.max(10, Number(expiryTime - FINAL_WARNING_BEFORE_LOGOUT) - currentTime),
        );

        setTimeout(
          () => {
            setUser({
              UserName: '',
              UserFullName: '',
              UserEmail: '',
              Roles: cookies.Role ? cookies.Role : [],
              PaymentMethod: cookies.PaymentMethod ? cookies.PaymentMethod : [],
              isLoggedIn: false,
              isLoading: false,
              logout: handleLogout,
              openLogoutDialog: false,
              updateOpenLogoutDialog: updateOpenLogoutDialog,
              HealthEntity: [],
              textDialog: '',
              openNoRoleDialog: false,
              updateOpenNoRoleDialog: updateOpenNoRoleDialog,
              defaultTerminalID: '',
            });

            window.location.href = LOGOUT_RELOGIN_URL;
          },
          Number(expiryTime) - currentTime,
        );
      }

      setUser({
        ...userDetails,
        isLoggedIn: true,
        isLoading: false,
        logout: handleLogout,
        openLogoutDialog: false,
        updateOpenLogoutDialog: updateOpenLogoutDialog,
        textDialog: '',
        openNoRoleDialog: false,
        updateOpenNoRoleDialog: updateOpenNoRoleDialog,
      });
    } else if (cookies.NoRoleLoggedIn) {
      setUser({
        UserName: '',
        UserFullName: '',
        UserEmail: '',
        Roles: cookies.Role ? cookies.Role : [],
        PaymentMethod: cookies.PaymentMethod ? cookies.PaymentMethod : [],
        isLoggedIn: false,
        isLoading: false,
        logout: handleLogout,
        openLogoutDialog: false,
        updateOpenLogoutDialog: updateOpenLogoutDialog,
        HealthEntity: [],
        textDialog: '',
        openNoRoleDialog: true,
        updateOpenNoRoleDialog: updateOpenNoRoleDialog,
        defaultTerminalID: '',
      });
      removeCookie('NoRoleLoggedIn');
    } else {
      setUser({
        UserName: '',
        UserFullName: '',
        UserEmail: '',
        Roles: cookies.Role ? cookies.Role : [],
        PaymentMethod: cookies.PaymentMethod ? cookies.PaymentMethod : [],
        isLoggedIn: false,
        isLoading: false,
        logout: handleLogout,
        openLogoutDialog: false,
        updateOpenLogoutDialog: updateOpenLogoutDialog,
        HealthEntity: [],
        textDialog: '',
        openNoRoleDialog: false,
        updateOpenNoRoleDialog: updateOpenNoRoleDialog,
        defaultTerminalID: '',
      });
    }
  }, [
    cookies.Role,
    cookies.UserFullName,
    cookies.UserName,
    cookies.UserEmail,
    handleLogout,
    cookies.NoRoleLoggedIn,
    cookies.AccessToken,
    cookies.PaymentMethod,
    cookies.HealthEntity,
    removeCookie,
    cookies.DefaultTerminalID,
  ]);

  return user;
}
