import { DateTime } from 'luxon';

/**
 * Get client browser information to be included in the CyberSource Payer Authentication Check Payer Auth Enrollment request.
 * Additional info is collected at the server-side, such as HTTP header values and IP address.
 *
 * See section: "Step 3: Payer Authentication Check Enrollment Service"
 * @see https://developer.cybersource.com/content/dam/docs/cybs/en-us/payer-authentication/developer/all/rest/payer-auth.pdf
 * @returns {object} browser information
 */
export function getBrowserInfo(): DeviceBrowserInfo {
  const clientTimeOffset = DateTime.now().offset;

  if (!window) return undefined;

  return {
    httpBrowserColorDepth: window.screen.colorDepth,
    httpBrowserJavaEnabled: window.navigator?.javaEnabled() || false,
    httpBrowserJavaScriptEnabled: true,
    httpBrowserLanguage: window.navigator.language || '',
    httpBrowserScreenHeight: window.screen.height,
    httpBrowserScreenWidth: window.screen.width,
    httpBrowserTimeDifference: clientTimeOffset,
  };
}
