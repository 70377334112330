import { paymentAPI } from "./api";
import { handleErrorMessage } from "./shared";

export const postValidateRecaptcha = async (recaptchaToken) => {
  try {
    const { data } = await paymentAPI.post(`/validate-recaptcha`, { recaptchaToken });
    return data;
  } catch (error) {
    return handleErrorMessage(error);
  }
};
