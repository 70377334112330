import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Features from '@src/features.config';

import GlobalAlert from '@components/Alert/GlobalAlert';
import NonProdWarning from '@components/Alert/NonProdWarning';
import Footer from '@components/Footer';
import Header from '@components/Header';
import IdleNotificationDialog from '@components/IdleNotificationDialog';
import { AuthLoadingContainer } from '@components/Loading';
import LogOutNotificationDialog from '@components/LogOutNotificationDialog';
import MainNav from '@components/MainNav';
import Masthead from '@components/Masthead';
import NoRoleDialog from '@components/NoRoleDialog';
import SnackbarNotification from '@components/SnackbarNotification';
import TerminalSelectPrompt from '@components/TerminalSelectPrompt';

import { scrollToTop } from '@helper/functions/scroller';
import useAuth from '@helper/hooks/useAuth';
import useOutageData from '@helper/hooks/useOutageData';

import { useAuthStore } from '@store/auth';
import useNavStore from '@store/nav';

import emotionTheme from '@theme/emotion.theme';
import GlobalStyles from '@theme/global.styles';
import { useOutageStore } from '@src/store/outage';

export default function Layout(props) {
  const { children } = props;

  const location = useLocation();
  const initializeAuth = useAuthStore((s) => s.initializeAuth);
  const initializeOutage = useOutageStore((s) =>s.initializeOutage);
  const navStore = useNavStore((s) => s);
  const transitionNodeRef = useRef(null);

  const user = useAuth();

  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  useEffect(() => {
    initializeOutage();
  }, [initializeOutage]);

  const [autoAnimateParent] = useAutoAnimate();

  const { outageData, isAlertOpen, setAlertClosed, alertType } = useOutageData();

  return (
    <EmotionThemeProvider theme={emotionTheme}>
      <div
        ref={autoAnimateParent}
        id="body-wrapper"
        className={clsx({
          'navigation-open': navStore.isNavigationOpen,
        })}
      >
        <GlobalStyles />

        <Masthead />
        {Features.nonProdWarning && <NonProdWarning />}
        {Features.admin.outageManager && isAlertOpen && (
          <GlobalAlert
            title={outageData?.outageTitle}
            message={outageData?.outageDetails}
            onClose={setAlertClosed}
            alertType={alertType}
            outageNotification = {true}
          />
        )}
        <Header />
        <MainNav />

        <div id="page-content">
          <AuthLoadingContainer>
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                nodeRef={transitionNodeRef}
                classNames="fade-enter"
                timeout={300}
                onEntered={() => scrollToTop()}
              >
                {children}
              </CSSTransition>
            </TransitionGroup>
          </AuthLoadingContainer>
        </div>

        <Footer />

        <SnackbarNotification />
        <IdleNotificationDialog />
        <NoRoleDialog isOpen={user.openNoRoleDialog} onClose={user.updateOpenNoRoleDialog} />
        <LogOutNotificationDialog
          isOpen={user.openLogoutDialog}
          onClose={user.updateOpenLogoutDialog}
          text={user.textDialog}
        />
        <TerminalSelectPrompt />
      </div>
    </EmotionThemeProvider>
  );
}
