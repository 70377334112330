import axios from 'axios';

import { useAuthStore } from '@store/auth';

export const paymentAPI = axios.create({
  baseURL: `${process.env.REACT_APP_PAYMENT_API_ENDPOINT}/hppapi`,
});
export function invoiceAPI(headersConfig = {}) {
  const api = axios.create({
    baseURL: process.env.REACT_APP_INVOICE_API_BASE_URL,
  });

  api.interceptors.request.use(async (config) => {
    let bearerToken = await useAuthStore.getState().getBearerToken();
    if (bearerToken) {
      config.headers['Authorization'] = bearerToken;
    }
    config.headers = {
      ...config.headers,
      ...headersConfig,
    };
    return config;
  });

  return api;
}

export function publicAPI(headersConfig = {}) {
  const api = axios.create({
    baseURL: process.env.REACT_APP_INVOICE_API_BASE_URL,
  });

  api.interceptors.request.use(async (config) => {
    config.headers = {
      ...config.headers,
      ...headersConfig,
    };
    return config;
  });

  return api;
}

export function invoiceAdminAPI(healthEntity = '', headersConfig = {}) {
  const api = axios.create({
    baseURL: process.env.REACT_APP_INVOICE_API_BASE_URL,
  });

  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('AccessToken='))
    ?.split('=')[1];

  api.interceptors.request.use((config) => {
    let bearerToken = cookieValue || '';
    if (bearerToken) {
      config.headers['Authorization'] = bearerToken;
    }
    if (healthEntity !== '') {
      config.headers['healthEntity'] = healthEntity;
    }

    config.headers = {
      ...config.headers,
      ...headersConfig,
    };
    return config;
  });

  return api;
}

export function secureAcceptanceAPI() {
  const api = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_API_ENDPOINT,
  });

  return api;
}

// export function secureAcceptanceAPI() {
//   const api = axios.create({
//     baseURL: process.env.REACT_APP_CYB_SECURE_ACCEPTANCE_URL,
//   });

//   return api;
// }

export function unifiedCheckoutAPI() {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_PAYMENT_API_ENDPOINT}`,
  });

  return api;
}

export function flexMicroformAPI() {
  const api = axios.create({
    baseURL: process.env.REACT_APP_PAYMENT_API_ENDPOINT,
  });

  return api;
}
